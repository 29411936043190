import {
  createRef
} from "react"
import {
  Vector3
} from "three"

const state = {
  sections: 9,
  pages: 8.5,
  zoom: 75,
  paragraphs: [{
      offset: 1,
      factor: 1.75,
      header: "What we do?",
      image: "/aboutus.jpg",
      aspect: 1.51,
      text: "We provide a comprehensive range of recruitment services designed to connect the world’s leading businesses and organizations with talented, driven employees. We know that recruitment can be arduous, and we’re here to lighten the load. Our services are mutually beneficial for candidates and company owners. If you’re looking to add bright sparks to your team, we’re here to help."
    },
    {
      offset: 2,
      factor: 2.0,
      header: "What we can do for you?",
      image: "/what.jpg",
      aspect: 1.5,
      text: "So, how can we help and wave a magic wand to solve your recruitment woes? Using our experience in the industry and in-depth knowledge of how dynamic, forward-thinking businesses work, we promote business growth, build motivated, dedicated and knowledgeable teams and match companies with candidates."
    },
    {
      offset: 3,
      factor: 1.5,
      header: "Candidates",
      image: "/candidate.jpg",
      aspect: 1.5,
      text: "We know that it’s not always easy to seek out a job that sounds great or to get a foot in the door in a competitive industry. We want to make sure that talented individuals earn the highest wages available in their chosen location and sector. Let us take care of finding a new job. Contact us, let's find your next pivot."
    },
    {
      offset: 4,
      factor: 2.0,
      header: "Team Work",
      image: "/rowing.jpg",
      aspect: 0.665,
      text: "When building a new team, or adding to an existing department, having an effective recruitment strategy is vital. As a business owner, you want to ensure that every new member of staff you hire adds value to your team and make sure that candidates fit in with the ethos of your company.  Finding the right people can be challenging and time-consuming on top of juggling a hundred other balls. This is where we can make a difference. "
    },
    {
      offset: 5,
      factor: 1.75,
      header: "Sector",
      image: "/sector.jpg",
      aspect: 1.55,
      text: "We cover a diverse range of sectors, working with clients across a broad spectrum of industries. Our specialty areas include: IT, Sales & Marketing, Telecoms, Media, Pharma, Manufacturing, Retail and Healthcare."
    },
    {
      offset: 7,
      factor: 1.05,
      header: "Contact us",
      image: "/contact.jpg",
      aspect: 1.77,
      text: "To find out more about the services we offer, don’t hesitate to get in touch. Call or email us on info@bondburry.com."
    }
  ],
  stripes: [{
      offset: 0,
      color: "#F2F2F2",
      height: 13
    },
    {
      offset: 6.3,
      color: "#F2F2F2",
      height: 20
    }
  ],
  diamonds: [{
      x: 0,
      offset: 0.15,
      pos: new Vector3(),
      scale: 0.6,
      factor: 1.8
    },
    {
      x: 2,
      offset: 1.1,
      pos: new Vector3(),
      scale: 0.8,
      factor: 2.1
    },
    {
      x: -5,
      offset: 2,
      pos: new Vector3(),
      scale: 0.8,
      factor: 2.5
    },
    {
      x: 0,
      offset: 3.2,
      pos: new Vector3(),
      scale: 0.8,
      factor: 1.75
    },
    {
      x: 0,
      offset: 4,
      pos: new Vector3(),
      scale: 0.8,
      factor: 2.5
    },
    {
      x: 2,
      offset: 5.5,
      pos: new Vector3(),
      scale: 1.25,
      factor: 0.85
    },
    {
      x: -5,
      offset: 7,
      pos: new Vector3(),
      scale: 0.8,
      factor: 2
    },
    {
      x: 0,
      offset: 8,
      pos: new Vector3(),
      scale: 1.5,
      factor: 6
    }
  ],
  top: createRef()
}

export default state